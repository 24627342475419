import 'swiper/css';
import 'swiper/css/scrollbar';

import { useTranslation } from 'next-i18next';
import React from 'react';
import { Autoplay, Keyboard, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import PlayerBox from '@/common/player-box';
import AffiliatesEnum from '@/enums/affiliates-enum';

export default function WhoUses() {
  const { t } = useTranslation(['pages/home', 'components/player-box']);
  const allAffiliates = AffiliatesEnum({ customList: false });

  return (
    <div className='flex items-center justify-center'>
      <div className='w-full max-w-[1400px] p-5 lg:pb-40'>
        <div className='flex flex-col items-start gap-5'>
          <h2 className='text-8-5xl font-bold leading-[110%] text-white'>{t('how_uses')}</h2>
          <p className='mb-0 pb-0 font-medium leading-[140%] text-[#8A8A9A]'>{t('how_uses_description')}</p>
        </div>

        <div className='flex w-full flex-1'>
          <div className='overflow-x-auto'>
            <Swiper
              loop
              autoplay={{
                delay: 0,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              }}
              speed={2000}
              spaceBetween={16}
              slidesPerView='auto'
              grabCursor
              breakpoints={{
                1024: { slidesPerView: 4 },
                767: { slidesPerView: 3 },
                478: { slidesPerView: 2 },
              }}
              scrollbar={{ draggable: true }}
              keyboard={{
                enabled: true,
              }}
              modules={[Keyboard, Autoplay, Scrollbar]}
              className='mySwiper mt-12'
            >
              {allAffiliates.map((affiliate) => (
                <div key={affiliate.name} className=''>
                  <SwiperSlide key={affiliate.name}>
                    <div className=''>
                      <PlayerBox player={affiliate} />
                    </div>
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
