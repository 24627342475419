import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

interface PlayerBoxProps {
  player: {
    name: string;
    game: string;
    title: string;
    country?: string;
    social_midia: {
      instagram: string;
      facebook: string;
      x: string;
      tiktok: string;
      youtube: string;
      twitch: string;
    };
  };
}

const PlayerBox = (props: PlayerBoxProps) => {
  const { player } = props;
  const { t } = useTranslation('components/player-box');

  return (
    <div className='relative flex h-[522px] w-full shrink-0 flex-col overflow-hidden rounded-xl bg-[#A8A8DC]'>
      <Image draggable={false} src={`/assets/images/who-uses/${player.name.toLowerCase().replace(' ', '').replace('_', '')}.webp`} height={240} width={372} alt={`Image ${player.name}`} className='w-full select-none' />
      <div className='flex size-full select-none flex-col justify-between gap-1 px-[30px]'>
        <div className='mt-4 flex max-w-[300px] flex-col gap-2'>
          <div className='flex items-center gap-2.5 text-lg font-semibold text-black'>
            <p className='text-[1.4rem] font-bold uppercase text-black'>{player.name}</p>
            {player.country && <Image src={player.country ?? ''} alt={player.name} width={24} height={18} />}
          </div>
          <div className='flex w-fit items-center justify-center rounded-[0.3125rem] bg-[#AEF55C] p-0 px-[1.375rem]'>
            <p className='text-sm font-bold uppercase leading-5 text-black'>{player.title}</p>
          </div>
          <p className='text-sm font-normal leading-4 text-black'>{t(`influencers.${player.name.toLowerCase().replace(' ', '').replace('_', '')}`)}</p>
        </div>

        <div className='mt-auto flex h-full flex-col justify-end pb-8'>
          <div className='mt-5 h-px w-full bg-black' />
          <div className='mt-3 flex items-center gap-2'>
            <img draggable={false} src='/assets/images/who-uses/control.webp' alt='player' height={13} width={18} />
            <span className='text-sm font-semibold uppercase leading-5 text-black'>{player.game}</span>

            <div className='ml-auto flex gap-1'>
              {player.social_midia.instagram.length > 1 && (
                <Link target='_blank' rel='noopener noreferrer' href={player.social_midia.instagram}>
                  <Image src='/assets/images/who-uses/social-midia/logo-instagram.webp' alt='logo instagram' width={14} height={15} />
                </Link>
              )}
              {player.social_midia.facebook.length > 1 && (
                <Link target='_blank' rel='noopener noreferrer' href={player.social_midia.facebook}>
                  <Image src='/assets/images/who-uses/social-midia/logo-facebook.webp' alt='logo facebook' width={14} height={15} />
                </Link>
              )}
              {player.social_midia.x.length > 1 && (
                <Link target='_blank' rel='noopener noreferrer' href={player.social_midia.x}>
                  <Image src='/assets/images/who-uses/social-midia/logo-x.webp' alt='logo x' width={14} height={15} />
                </Link>
              )}
              {player.social_midia.tiktok.length > 1 && (
                <Link target='_blank' rel='noopener noreferrer' href={player.social_midia.tiktok}>
                  <Image src='/assets/images/who-uses/social-midia/logo-tiktok.webp' alt='logo tiktok' width={13} height={15} />
                </Link>
              )}
              {player.social_midia.youtube.length > 1 && (
                <Link target='_blank' rel='noopener noreferrer' href={player.social_midia.youtube}>
                  <Image src='/assets/images/who-uses/social-midia/logo-youtube.webp' alt='logo youtube' width={15} height={15} />
                </Link>
              )}
              {player.social_midia.twitch.length > 1 && (
                <Link target='_blank' rel='noopener noreferrer' href={player.social_midia.twitch}>
                  <Image src='/assets/images/who-uses/social-midia/logo-twitch.webp' alt='logo twitch' width={14} height={15} />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerBox;
