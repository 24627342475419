import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

function shuffleArray(array: any[]) {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

interface AffiliatesEnumProps {
  customList: boolean;
}

const AffiliatesEnum = ({ customList }: AffiliatesEnumProps) => {
  const { t } = useTranslation('components/player-box');

  const affiliates = {
    ARKANE: {
      name: 'Arkane',
      country: '/assets/images/who-uses/flags/br.webp',
      game: 'FORTNITE',
      title: t('influencer'),
      social_midia: {
        instagram: 'https://www.instagram.com/arkane/',
        facebook: '/',
        x: 'https://x.com/heyarkane',
        tiktok: 'https://www.tiktok.com/@arkaneoficial',
        youtube: '/',
        twitch: 'https://www.twitch.tv/arkane',
      },
    },
    BRTT: {
      name: 'BRTT',
      country: '/assets/images/who-uses/flags/br.webp',
      game: 'lol',
      title: t('brazilian_champion', { quantity: 6 }),
      social_midia: {
        instagram: 'https://www.instagram.com/brttoficial/',
        facebook: '/',
        x: 'https://x.com/brttOficial',
        tiktok: '/',
        youtube: 'https://www.youtube.com/@Oquequehouve',
        twitch: 'https://www.twitch.tv/brtt',
      },
    },
    COLDZERA: {
      name: 'coldzera',
      country: '/assets/images/who-uses/flags/br.webp',
      game: 'CS2',
      title: t('best_world_cs'),
      social_midia: {
        instagram: 'https://www.instagram.com/coldzera',
        facebook: '/',
        x: 'https://x.com/coldzera',
        tiktok: '/',
        youtube: 'https://www.youtube.com/@Coldzera',
        twitch: 'https://www.twitch.tv/coldzin',
      },
    },
    // CS2NADES: {
    //   name: 'CS Nades',
    //   country: '/assets/images/who-uses/flags/br.webp',
    //   game: 'CS2',
    //   title: t('content_creator'),
    //   social_midia: {
    //     instagram: 'https://www.instagram.com/goldcsnades',
    //     facebook: '/',
    //     x: 'https://x.com/goldcsnades',
    //     tiktok: 'https://www.tiktok.com/@goldcsnades',
    //     youtube: 'https://www.youtube.com/@goldcsnades',
    //     twitch: '/',
    //   },
    // },
    // FELPS: {
    //   name: 'Felps',
    //   country: '/assets/images/who-uses/flags/br.webp',
    //   game: 'cs2',
    //   title: t('major_cs'),
    //   social_midia: {
    //     instagram: 'https://www.instagram.com/felpsplayer/',
    //     facebook: '/',
    //     x: 'https://x.com/felperaa',
    //     tiktok: '/',
    //     youtube: 'https://www.youtube.com/felpera',
    //     twitch: 'https://www.twitch.tv/fpsfelps',
    //   },
    // },
    // KHANADA: {
    //   name: 'KHANADA',
    //   country: '/assets/images/who-uses/flags/en.webp',
    //   game: 'FORTNITE',
    //   title: 'PRO PLAYER',
    //   social_midia: {
    //     instagram: 'https://www.instagram.com/tsmkhanada/',
    //     facebook: '/',
    //     x: 'https://x.com/Khanada',
    //     tiktok: 'https://www.tiktok.com/@khanadatv',
    //     youtube: 'https://www.youtube.com/@Khanada',
    //     twitch: 'https://www.twitch.tv/khanada_',
    //   },
    // },
    LESTRIPEZ: {
      name: 'Lestripez',
      country: '/assets/images/who-uses/flags/de.webp',
      game: 'fortnite',
      title: t('influencer'),
      social_midia: {
        instagram: 'https://www.tiktok.com/@lestripezyt',
        facebook: '/',
        x: 'https://x.com/StripeLe',
        tiktok: 'https://www.tiktok.com/@lestripezyt',
        youtube: 'https://www.youtube.com/leStripeZ',
        twitch: '/',
      },
    },
    // PINQ: {
    //   name: 'PINQ',
    //   country: '/assets/images/who-uses/flags/uk.webp',
    //   game: 'fortnite',
    //   title: 'PRO PLAYER',
    //   social_midia: {
    //     instagram: 'https://www.instagram.com/pinqfn/',
    //     facebook: '/',
    //     x: 'https://x.com/Pinq',
    //     tiktok: 'https://www.tiktok.com/@pinqlive_',
    //     youtube: 'https://www.youtube.com/@PinqUK/videos',
    //     twitch: 'https://www.twitch.tv/pinqeu',
    //   },
    // },
    SACY: {
      name: 'Sacy',
      country: '/assets/images/who-uses/flags/br.webp',
      game: 'VALORANT',
      title: t('valorant_champion'),
      social_midia: {
        instagram: 'https://www.instagram.com/sacyrossi/',
        facebook: '/',
        x: 'https://x.com/sen_sacy',
        tiktok: 'https://www.tiktok.com/@sacy_rossi',
        youtube: 'https://www.youtube.com/c/Sacyadc',
        twitch: 'https://www.twitch.tv/sacy',
      },
    },
    VETT: {
      name: 'Vett',
      country: '/assets/images/who-uses/flags/br.webp',
      game: 'pb',
      title: t('brazilian_champion', { quantity: 5 }),
      social_midia: {
        instagram: 'https://www.instagram.com/flavio.vett/',
        facebook: '/',
        x: 'https://x.com/flavio_vett',
        tiktok: 'https://www.tiktok.com/@vettao',
        youtube: 'https://www.youtube.com/@Vettao',
        twitch: 'https://www.twitch.tv/unvett',
      },
    },
    SUJA: {
      name: 'Suja',
      country: '/assets/images/who-uses/flags/es.webp',
      game: 'fortnite',
      title: t('influencer'),
      social_midia: {
        instagram: 'https://www.instagram.com/suja_gg',
        facebook: '/',
        x: 'https://x.com/suja_gg',
        tiktok: 'https://www.tiktok.com/@suja_gg',
        youtube: 'https://www.youtube.com/SUJAYoutube',
        twitch: 'https://www.twitch.tv/suja',
      },
    },
    // COOPER: {
    //   name: 'COOPER',
    //   country: '/assets/images/who-uses/flags/en.webp',
    //   game: 'fortnite',
    //   title: 'PRO PLAYER',
    //   social_midia: {
    //     instagram: '/',
    //     facebook: '/',
    //     x: 'https://x.com/cooperfnbr',
    //     tiktok: 'https://www.tiktok.com/@cooperfnbr__',
    //     youtube: 'https://www.youtube.com/@CooperFNBR',
    //     twitch: 'https://www.twitch.tv/cooper',
    //   },
    // },
    // TEMET: {
    //   name: 'TEMET',
    //   country: '/assets/images/who-uses/flags/uk.webp',
    //   game: 'FORTNITE',
    //   title: t('influencer'),
    //   social_midia: {
    //     instagram: 'https://www.instagram.com/itstemet',
    //     facebook: '/',
    //     x: 'https://x.com/temets',
    //     tiktok: 'https://www.tiktok.com/@temets',
    //     youtube: 'https://www.youtube.com/@temets',
    //     twitch: 'https://www.twitch.tv/temet',
    //   },
    // },
  };

  const [shuffledItems, setShuffledItems] = useState(Object.values(affiliates));

  useEffect(() => {
    if (!customList) {
      setShuffledItems(shuffleArray(Object.values(affiliates)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return shuffledItems;
};

export default AffiliatesEnum;
